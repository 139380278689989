<template>
  <div
    class="app-dwm-checkbox"
    :class="{
      'app-dwm-checkbox--infopopup': infoAsPopup,
      'app-dwm-checkbox--locked': locked,
      'app-dwm-checkbox--disabled': disabled,
    }"
  >
    <label class="app-dwm-checkbox__checkbox">
      <input
        v-model="checkedList"
        :value="value"
        type="checkbox"
        class="app-dwm-checkbox__input"
        :disabled="disabled"
      >
      <span class="app-dwm-checkbox__label">{{ label }}</span>
      <span
        v-if="info"
        class="app-dwm-checkbox__info"
      >{{ info }}</span>
    </label>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    infoAsPopup: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Array,
      default: () => [],
    },
    value: {
      type: null,
      required: true,
    },
  },
  computed: {
    checkedList: {
      get() {
        return this.checked;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
};
</script>

<style lang="scss">
.app-dwm-checkbox {
  $root: &;

  display: flex;
  font-size: 10px;
  line-height: 16px;

  &--infopopup {
    position: relative;
    justify-content: flex-end;
  }

  &__checkbox {
    display: flex;
    user-select: none;
  }

  &__input {
    display: none;

    & ~ * {
      cursor: pointer;
    }

    &:disabled ~ * {
      cursor: default;
    }
  }

  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    text-align: center;

    color: $result-dwm-color;
    background-color: $result-dwm-bg-color;
    border: $result-dwm-border;

    #{$root}:hover & {
      color: $result-dwm-color-hover;
      background-color: $result-dwm-bg-color-hover;
      border-color: $result-dwm-border-color-hover;
    }

    #{$root}__input:checked + & {
      color: $result-dwm-color-active;
      background-color: $result-dwm-bg-color-active;
      border-color: $result-dwm-border-color-active;

      font-weight: bold;

      #{$root}:hover & {
        color: $result-dwm-color-active-hover;
        background-color: $result-dwm-bg-color-active-hover;
        border-color: $result-dwm-border-color-active-hover;
      }
    }

    #{$root}--locked #{$root}__input + &,
    #{$root}--locked:hover #{$root}__input + &,
    #{$root}--locked:hover #{$root}__input:checked + & {
      color: $result-dwm-color-locked;
      background-color: $result-dwm-bg-color-locked;
      border-color: $result-dwm-border-color-locked;
    }

    #{$root}--locked #{$root}__input:checked + &,
    #{$root}--locked:hover #{$root}__input:checked + & {
      color: $result-dwm-color-active-locked;
      background-color: $result-dwm-bg-color-active-locked;
      border-color: $result-dwm-border-color-active-locked;

      border-color: $font-color-primary;
      font-weight: bold;
    }
  }

  &__info {
    margin-left: 8px;
    white-space: pre-line;

    #{$root}--infopopup & {
      order: 0;
      display: none;
      position: absolute;
      left: 50%;
      transform: translateY(-100%) translateY(-12px) translateX(-50%);

      min-width: 170px;

      padding: 8px;
      background: $dwm-infopopup-bg-color;
      border: $dwm-infopopup-border;
      border-radius: 10px;

      margin: 0;

      &::after {
        content: '';
        display: block;
        width: 13px;
        height: 13px;
        left: 50%;
        transform: translateY(2px) translateX(-50%) rotate(45deg);
        position: absolute;
        background: $dwm-infopopup-bg-color;
        border-bottom: $dwm-infopopup-border;
        border-right: $dwm-infopopup-border;
      }
    }

    #{$root}--infopopup:hover & {
      display: block;
      &:hover {
        display: none;
      }
    }
  }
}

</style>
