<template>
  <date-picker
    v-model="selectedDateRange"
    class="app-select-daterange"
    :input-class="'app-select-daterange__input'"
    :popup-class="'app-select-daterange__popup'"
    type="date"
    range
    confirm
    format="DD.MM.YYYY"
    :placeholder="placeholder"
    :show-week-number="true"
    :default-value="firstAllowedDate"
    :disabled-date="(date) => (date < firstAllowedDate || date > lastAllowedDate)"
    :disabled="false"
    value-type="format"
  >
    <template v-slot:header>
      <div class="app-select-daterange__header">
        <span class="app-select-daterange__date-name">von</span>
        <span class="app-select-daterange__date-name">bis</span>
      </div>
    </template>
  </date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [null, null],
    },
    disableBefore: {
      type: Date,
      default: () => new Date(0),
    },
    disableAfter: {
      type: Date,
      default: () => new Date(new Date().setYear(2050)),
    },

  },
  computed: {
    selectedDateRange: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); },
    },
    firstAllowedDate() {
      return new Date(Math.max(this.disableBefore, this.$store.getters.firstAllowedDate.toDate()));
    },
    lastAllowedDate() {
      return new Date(Math.min(this.disableAfter, this.$store.getters.lastAllowedDate.toDate()));
    },
  },
};
</script>

<style lang="scss">
.app-select-daterange {
  width: 100%;

  margin-bottom: 12px;

  &__input {
    @include input;
    z-index: 0;
    color: $input-result-color;
    font-weight: 700;
  }

  &__popup {
    border: $input-select-options-border;
    z-index: 100000;

    .mx-range-wrapper {
      @media (max-width: 750px) {
        flex-direction: row;
        max-width: 89.7vw;

        .mx-calendar-header {
          max-width: 150px;
          margin: 0 auto;

          button {
            font-size: 12px;
          }
        }

        td {
          font-size: 8px !important;
        }

        th {
          font-size: 10px !important;
        }
      }
    }
  }

  &__header {
    background-color: $input-select-options-header-bg-color;
    display: flex;
    justify-content: space-around;
  }

  &__date-name {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 1000;
    color: $input-select-options-header-color;
  }

  svg {
    fill: $input-select-result-color;
    cursor: pointer;
  }
}
</style>
