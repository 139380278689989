<template>
  <div
    class="app-loader"
    :class="[fixed_class]"
  >
    <img
      src="./img/loader_omni.gif"
      alt="LOADER"
      class="app-loader__image"
    >
  </div>
</template>

<script>

const fixedClass = {
  desktop: true,
  tablet: true,
  mobile: true,
};

export default {
  components: {
  },
  props: {
    fixedOn: { type: String, default: '' }, // desktop | tablet | mobile
  },
  computed: {
    fixed_class() {
      return this.fixedOn && this.fixedOn in fixedClass ? `fixed-on-${this.fixedOn}` : false;
    },
  },
};
</script>

<style lang="scss">
$z-index-loader: 50;

.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $loader-bg-color;
  border-radius: inherit;
  margin: 0;
  padding: 20px;
  z-index: $z-index-loader;

  img {
    content: "";
    width: 150px;
    max-width: 95%;
    object-fit: contain;
  }

  &~* {
    .app-loader {
        display: none;
    }
  }
}
</style>
